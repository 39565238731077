import React, { useState } from "react"
import { graphql } from "gatsby"
import { getDistance } from "geolib"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SearchBar from "../components/SearchBar"
import TeaserPost from "../components/TeaserPost"

import { Select, withStyles } from "@material-ui/core"

import InputBase from "@material-ui/core/InputBase"

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase)

const Angebote = ({ data }) => {
  const [parameters, setParameters] = useState({
    type: "",
    art: "",
    location: undefined,
    distance: 10,
  })

  //so würde es dann richtig aussehen
  const [sortBy, setSortBy] = useState("flaecheAbsteigend")

  const handleSearchClick = parameters => {
    console.log("new parameters", parameters)
    setParameters(parameters)
  }

  const sortHandleChange = event => {
    setSortBy(event.target.value)
  }
  const isPropertyInRange = (latLonProperty, latLonUserInput, rangeInKm) => {
    console.log(
      "lProperty",
      latLonProperty,
      "lUser",
      {
        latitude: latLonUserInput.lat,
        longitude: latLonUserInput.lng,
      },
      "lRange",
      rangeInKm
    )
    const distance = getDistance(
      {
        latitude: latLonProperty.latitude,
        longitude: latLonProperty.longitude,
      },
      {
        latitude: latLonUserInput.lat,
        longitude: latLonUserInput.lng,
      }
    )
    return distance / 1000 <= rangeInKm
  }
  const filterLogic = post => {
    let shouldStayInArray = true
    if (parameters.art !== "")
      shouldStayInArray = post.node.acf_offer.art === parameters.art
    if (parameters.type !== "" && shouldStayInArray)
      shouldStayInArray = post.node.acf_offer.objekttyp === parameters.type
    if (parameters.location !== undefined && shouldStayInArray) {
      if (post.node.acf_offer.kartenausschnitt && parameters.distance) {
        shouldStayInArray = isPropertyInRange(
          post.node.acf_offer.kartenausschnitt,
          parameters.location,
          parameters.distance
        )
      }
    }
    return shouldStayInArray
  }

  const sortLogic = (a, b) => {
    switch (sortBy) {
      case "priceAscending":
        if (a.node.acf_offer.cost < b.node.acf_offer.cost) return -1
        else if (a.node.acf_offer.cost === b.node.acf_offer.cost) return 0
        else return 1

      case "priceDescending":
        if (a.node.acf_offer.cost < b.node.acf_offer.cost) return 1
        else if (a.node.acf_offer.cost === b.node.acf_offer.cost) return 0
        else return -1

      case "areaAscending":
        if (a.node.acf_offer.flaeche < b.node.acf_offer.flaeche) return 1
        else if (a.node.acf_offer.flaeche === b.node.acf_offer.flaeche) return 0
        else return -1

      case "areaDescending":
        if (a.node.acf_offer.flaeche < b.node.acf_offer.flaeche) return -1
        else if (a.node.acf_offer.flaeche === b.node.acf_offer.flaeche) return 0
        else return 1
      default:
        return 0
    }
  }

  const renderPosts = () => {
    const posts = data.wpgraphql.offers.edges
      .filter(filterLogic)
      .sort((a, b) => sortLogic(a, b))
      .map(post => <TeaserPost post={post} data={data} />)
    if (posts.length > 0) return posts
    else
      return (
        <Row className="listing-container">
          <Col>
            <p className="text-center mb-0 pb-0">
              <strong>
                Leider keine Einträge gefunden.
                <br /> Viele Angebote werden von uns diskret vermittelt!
              </strong>
            </p>
          </Col>
        </Row>
      )
  }

  return (
    <Layout>
      {/* //TODO SEO */}
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />

      <Container fluid>
        <Row id="portal" className="d-flex  align-items-center">
          <Col md={{ span: 6, offset: 3 }} sm={12} xs={12}>
            <SearchBar onSearchClick={handleSearchClick} />
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="mb-3">
          <Col className="mt-3">
            <Select
              native
              onChange={sortHandleChange}
              input={<BootstrapInput name="" id="" />}
            >
              <option value="" disabled selected>
                Sortieren nach
              </option>

              <option value={"priceAscending"}>Preis aufsteigend</option>
              <option value={"priceDescending"}>Preis absteigend</option>
              <option value={"areaAscending"}>Fläche aufsteigend</option>
              <option value={"areaDescending"}>Fläche absteigend</option>
            </Select>
          </Col>
        </Row>
        {renderPosts()}
      </Container>
    </Layout>
  )
}

export default Angebote

export const query = graphql`
  query {
    wpgraphql {
      offers(first: 200) {
        edges {
          node {
            title
            slug
            acf_offer {
              cost
              costcustomtext
              excerpt
              lagebeschreibung
              objektbeschreibung
              objekttyp
              sonstiges
              flaeche
              flaecheTyp
              kaeuferprovision
              status
              besichtigungstermin
              immobilienanbieter
              art
              kartenausschnitt {
                latitude
                longitude
                city
              }
              beitragsbild {
                mediaItemUrl
                sourceUrl
                mediaItemId
                modified
                imageFile {
                  childImageSharp {
                    sizes(maxWidth: 600) {
                      ...GatsbyImageSharpSizes
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    logoImmobilien: file(relativePath: { eq: "logo_immo.png" }) {
      ...squareImage
    }
  }
`
