import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import '@fortawesome/fontawesome-svg-core/styles.css';
import Chip from '@material-ui/core/Chip';
import Img from "gatsby-image"
function calculateSomething(kaufpreis, flaeche) {
  if(!kaufpreis) return "keine Angabe"
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits:0,
    maximumFractionDigits:0,
  }).format(kaufpreis / flaeche)
}

function generateHeadline(art) {
  switch (art){
    case "Vermieten": return "Mietpreis";
    case "Verkaufen": return "Kaufpreis";
    case "Verpachten": return "Pachtpreis";

    default: return "Kaufpreis";

  }
}

function generateSpace(space){
  switch (space){
    case "Vermieten": return "Mietfläche";
    case "Verkaufen": return "Größe";
    case "Verpachten": return "Pachtfläche";
  }
}

const TeaserPost = props => {
  const { post,data } = props
  console.log("Post",JSON.stringify(post))
  return (
    
    
      <Row className="listing-container">
        <Col md={4}>
          <Link to={`/angebote/${post.node.slug}`}>
          {post.node.acf_offer.beitragsbild && post.node.acf_offer.beitragsbild.imageFile ? (
            <Img
              className="img-fluid"
              sizes={post.node.acf_offer.beitragsbild.imageFile.childImageSharp.sizes}
              alt={post.node.title}
            />
          ) : (
            <Img
            fixed={data.logoImmobilien.childImageSharp.fixed}
            style={{ height: "200px", margin:'auto' }}
          />
          )}
          </Link>
          <br/>
         
          
        </Col>
        <Col md={8}>
          <Row>
            <Col >
            <Link to={`/angebote/${post.node.slug}`}>
              <h3
                dangerouslySetInnerHTML={{ __html: post.node.title }}
              
              />
             </Link>
            </Col>
          </Row>
          <Row>
            <Col className="mb-3"md ={3}>
            <Chip className="chip" label={post.node.acf_offer.status} />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <strong>
              
                {!post.node.acf_offer.cost &&
                  post.node.acf_offer.costcustomtext}
                {post.node.acf_offer.cost &&
                  new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits:0,
                  maximumFractionDigits:0,
                  }).format(post.node.acf_offer.cost)}{" "}
              </strong>
              <br />
                    
              {generateHeadline(post.node.acf_offer.art)}
              
            </Col>
            <Col md={6}>
             <strong>  {post.node.acf_offer.flaeche}{" "}{post.node.acf_offer.flaecheTyp}</strong>
           
                  <br />
           
           
                  {generateSpace(post.node.acf_offer.art)}
            </Col>
            <Col>
              <strong>
                {calculateSomething(
                  post.node.acf_offer.cost,
                  post.node.acf_offer.flaeche
                  
                )}
              </strong>
              <br />
              Preis pro {post.node.acf_offer.flaecheTyp}
            </Col>
          </Row>
          <Row>
            <Col>
              {post.node.acf_offer.excerpt &&<p
                dangerouslySetInnerHTML={{
                  __html: post.node.acf_offer.excerpt,
                }}
              />}
             
            </Col>
          </Row>
          
        </Col>
       {/* <div className="statusDiv"> <a class ="status"> <FontAwesomeIcon className ="statusIcon" icon= {faChevronRight} style={{marginRight: "10px"}}color={"#0e672f"}/>{post.node.acf_offer.status}</a></div> */}
      </Row>
  
  )
}

TeaserPost.propTypes = {}

export default TeaserPost
